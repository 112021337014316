var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", persistent: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [
              _vm._v("Filtro Avançado"),
            ]),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-container",
                    { attrs: { "grid-list-lg": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { "align-center": "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "menu",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "nudge-right": 40,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "full-width": "",
                                    "max-width": "290px",
                                    "min-width": "290px",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  label: "Data Inicial",
                                                  readonly: "",
                                                  "append-icon": "mdi-calendar",
                                                  placeholder: "Selecione",
                                                },
                                                model: {
                                                  value:
                                                    _vm.initialBrazilianDate,
                                                  callback: function ($$v) {
                                                    _vm.initialBrazilianDate =
                                                      $$v
                                                  },
                                                  expression:
                                                    "initialBrazilianDate",
                                                },
                                              },
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.showInitialDatepicker,
                                    callback: function ($$v) {
                                      _vm.showInitialDatepicker = $$v
                                    },
                                    expression: "showInitialDatepicker",
                                  },
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: {
                                      "no-title": "",
                                      scrollable: "",
                                      locale: "pt-br",
                                    },
                                    on: { change: _vm.setInitialDate },
                                    model: {
                                      value: _vm.fields.initial_date,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.fields,
                                          "initial_date",
                                          $$v
                                        )
                                      },
                                      expression: "fields.initial_date",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs4: "" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "menu",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "nudge-right": 40,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "full-width": "",
                                    "max-width": "290px",
                                    "min-width": "290px",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  label: "Data Final",
                                                  readonly: "",
                                                  "append-icon": "mdi-calendar",
                                                  placeholder: "Selecione",
                                                },
                                                model: {
                                                  value: _vm.finalBrazilianDate,
                                                  callback: function ($$v) {
                                                    _vm.finalBrazilianDate = $$v
                                                  },
                                                  expression:
                                                    "finalBrazilianDate",
                                                },
                                              },
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.showFinalDatepicker,
                                    callback: function ($$v) {
                                      _vm.showFinalDatepicker = $$v
                                    },
                                    expression: "showFinalDatepicker",
                                  },
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: {
                                      "no-title": "",
                                      scrollable: "",
                                      locale: "pt-br",
                                    },
                                    on: { change: _vm.setFinalDate },
                                    model: {
                                      value: _vm.fields.final_date,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.fields, "final_date", $$v)
                                      },
                                      expression: "fields.final_date",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Gerador",
                                  placeholder:
                                    "Buscar por nome, razão social, CPF ou CNPJ",
                                },
                                model: {
                                  value: _vm.fields.generator,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.fields,
                                      "generator",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "fields.generator",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Armazenador Temporário",
                                  placeholder:
                                    "Buscar por nome, razão social, CPF ou CNPJ",
                                },
                                model: {
                                  value: _vm.fields.temporary_storage,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.fields,
                                      "temporary_storage",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "fields.temporary_storage",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Transportador",
                                  placeholder:
                                    "Buscar por nome, razão social, CPF ou CNPJ",
                                },
                                model: {
                                  value: _vm.fields.transporter,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.fields,
                                      "transporter",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "fields.transporter",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Destinador",
                                  placeholder:
                                    "Buscar por nome, razão social, CPF ou CNPJ",
                                },
                                model: {
                                  value: _vm.fields.receiver,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.fields,
                                      "receiver",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "fields.receiver",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c("v-btn", { attrs: { flat: "" }, on: { click: _vm.close } }, [
                _vm._v("Cancelar"),
              ]),
              _c(
                "v-btn",
                {
                  attrs: { disabled: !_vm.valid, color: "primary" },
                  on: { click: _vm.emitFields },
                },
                [_vm._v("Buscar")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }