import dateFns from 'date-fns';
import pt from 'date-fns/locale/pt';

const formattedDateMixin = {
  filters: {
    formattedDate(value) {
      if (!value) return null;
      return dateFns.format(value, 'DD/MM/YYYY');
    },
  },
};

export const formatBrazilianDate = (date) => {
  if (!date) {
    return null;
  }
  // const [year, month, day] = date.split('-');
  return dateFns.format(date, 'DD/MM/YYYY');
};

export const parseISODate = (date) => {
  if (!date) {
    return null;
  }
  const [day, month, year] = date.split('/');
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

export default formattedDateMixin;
