<template>
  <v-dialog v-model="show" width="500" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Filtro Avançado</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-container grid-list-lg>
            <v-layout align-center wrap>
              <v-flex xs4>
                <v-menu
                  ref="menu"
                  v-model="showInitialDatepicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      label="Data Inicial"
                      v-model="initialBrazilianDate"
                      readonly
                      v-on="on"
                      append-icon="mdi-calendar"
                      placeholder="Selecione"
                    />
                  </template>
                  <v-date-picker
                    v-model="fields.initial_date"
                    no-title
                    scrollable
                    locale="pt-br"
                    @change="setInitialDate"
                  />
                </v-menu>
              </v-flex>
              <v-flex xs4>
                <v-menu
                  ref="menu"
                  v-model="showFinalDatepicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      label="Data Final"
                      v-model="finalBrazilianDate"
                      readonly
                      v-on="on"
                      append-icon="mdi-calendar"
                      placeholder="Selecione"
                    />
                  </template>
                  <v-date-picker
                    v-model="fields.final_date"
                    no-title
                    scrollable
                    locale="pt-br"
                    @change="setFinalDate"
                  />
                </v-menu>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model.trim="fields.generator"
                  label="Gerador"
                  placeholder="Buscar por nome, razão social, CPF ou CNPJ"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model.trim="fields.temporary_storage"
                  label="Armazenador Temporário"
                  placeholder="Buscar por nome, razão social, CPF ou CNPJ"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model.trim="fields.transporter"
                  label="Transportador"
                  placeholder="Buscar por nome, razão social, CPF ou CNPJ"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model.trim="fields.receiver"
                  label="Destinador"
                  placeholder="Buscar por nome, razão social, CPF ou CNPJ"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="close" flat>Cancelar</v-btn>
        <v-btn :disabled="!valid" @click="emitFields" color="primary">Buscar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {formatBrazilianDate, parseISODate} from '@/mixins/formattedDateFilter';

export default {
  name: 'AdvancedFilterModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: {
        initial_date: '',
        final_date: '',
        transporter: '',
        receiver: '',
        generator: '',
        temporary_storage: '',
      },
      valid: false,
      initialBrazilianDate: '',
      finalBrazilianDate: '',
      showInitialDatepicker: false,
      showFinalDatepicker: false,
      deleteDialog: false,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    emitFields() {
      this.$emit('emit-fields', this.fields);
    },
    toggleDatePicker(datepicker, value) {
      setTimeout(() => {
        this[datepicker] = value;
      }, 200);
    },
    setInitialDate() {
      this.initialBrazilianDate = formatBrazilianDate(this.fields.initial_date);
      this.fields.initial_date = parseISODate(this.initialBrazilianDate);
      this.toggleDatePicker('showInitialDatepicker', false);
    },
    setFinalDate() {
      this.finalBrazilianDate = formatBrazilianDate(this.fields.final_date);
      this.fields.final_date = parseISODate(this.finalBrazilianDate);
      this.toggleDatePicker('showFinalDatepicker', false);
    },
  },
};
</script>
